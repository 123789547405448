
/**
** Contents
*
* 1.  NavBar
* 2.  Form
* 3.  QR code Modal
* 4.  Modal
* 5.  Autosuggestion Input
* 6.  Checkbox Input Group
* 7.  Input Email
* 8.  Responsive
* 9.  Accordion
* 10. History Group List
* 11. Support Lead
* 12. Underline Nav
* 13. Login page


/*  NavBar */
.navbar-logo {
  max-height: 30px;
  padding-right: 1rem;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
}
@media (max-width: 576px) {
  .navbar-brand {
    font-size: 1rem;
  }
}


/*  Form */
.form-feedback .alert {
  display: none;
  margin-top: 1rem;
}
.form-feedback.show {
  opacity: 1;
  transition: opacity 400ms;
}
.form-feedback.hide {
  opacity: 0;
}
.feedback-sucess {
  color: #28a745;
}
.feedback-error {
  color: #dc3545;
}
label {
  display: block;
}
/*  ... */
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

/*  QR Code modal */
/*.modal-content {
  background: none;
  border: none;
}
.modal-body {
  background-color: #fff;
  padding: 0;
}
.qr-submit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}*/

/*  Modal */
.modal-header,
.modal-footer {
  border: none;
}

/* Autosuggestion Input */
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.react-autosuggest__input:focus {
  outline: none;
}
.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 37px;
  /* width: 280px; */
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}
.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff;
}
.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
.highlight {
  color: #ee0000;
  font-weight: bold;
}

/* Checkbox Input Group */
.btn-group.fullwidth {
  display:flex;
}
.btn-group.fullwidth .btn{
  padding-left: 0;
  padding-right: 0;
}
.btn-group .btn-secondary {
  border-color: #9299a0;
}

/* Input Email */
.form-group.dropdown label svg{
  float: right;
  margin-right: calc(.375rem + 1px);
  cursor: pointer;
}


/* Responsive */
@media (max-width: 35.99rem) {
  .no-margin-sm {
    margin: 0 -15px !important;
    min-height: calc(100vh - 100px);
  }
}

/* Responsive */
.total {
  text-align: right;
  padding-top: 1rem;
  margin-right: 0.5rem;
}
.price {
  display: inline-block;
  width: 3.75rem;
}

/* Accordion */
.card-body .form-group:last-child {
  margin-bottom: 0;
}

/* History Group List */
.list-group-item.actionable {
  background-color: #d1ecf1;
}
.list-group-item .actions-wrapper {
  display: none;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid #00000020;
}
.list-group-item.actionable .actions-wrapper{
  display: flex;
}

/* Support Lead */
.support-lead {
  padding-top: 5rem;
}

/* Underline Nav */
.nav-link {
  padding: 0.5rem 0.5rem;
}

/* Login page */
.container.full-height {
  height: 100vh;
  margin-top: -56px;
}
.signin-logo-wrapper {
  flex-grow: 1;
  text-align: center;
  padding-top: calc((100vh / 2) - (100vw / 2));
}
.signin-logo {
  max-height: calc((100vw / 3) * 2);
}
.fade-in {
  opacity: 0;
}
.fade-in.visible {
  transition: opacity 1s ease-in;
  opacity: 1;
}
#signin {
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
}